import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
// import ReservationForm from "../components/globals/ReservationForm"

import img from "../images/bcg/all-you-can-eat-crab-legs.jpg"

const CrabPage = ({ location }) => (
  <Layout>
    <SEO
      title="Crab Leg Tuesday's"
      desc="All You Can Eat Crab Legs on Lake Hartwell in Clemson, SC"
      img={img}
      url={location.href}
    />
    <AboutHeader img={img}>
      <BannerLeft title="Tuesday Crab Legs" subtitle="Coming Soon! Check back for details">
        <hr />
        <p>
          Tuesday Crab Legs is a seasonal thing, and we promise it will return. Follow us on social media or join our newsletter for future announcements.
        </p>
      </BannerLeft>
    </AboutHeader>

    <section id="special">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>All you can eat crab legs</h2>
          <br />
          <p>
            [Not currently offered, but coming soon!] The second Tuesday of every month from 4-9 p.m. @ Traditions is ALL YOU CAN EAT crab legs with Creole garlic butter and fries! We look forward to having you as our guest!
          </p>
          <br />
          <p>
           $50 per person (no sharing please, 2 hour time limit).
          </p>
          <br />
          <p>
           4 p.m. to 9 p.m.
          </p>


        </div>
      </div>

      <h2>Join us on the lake!</h2>

      <br />
      <br />
      <br />
      <br />
    </section>
  </Layout>
)

export default CrabPage
